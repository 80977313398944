* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
}

body {
  margin: 0;
  font-family: "Roboto", "Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

html {
  height: 100%;
}

.react-tel-input .form-control + div:before {
  content: "Phone Number";
}

.page--drawer-open {
  position: absolute;
  left: 290px;
  right: 64px;
  margin-bottom: 90px;
  transition: all ease 0.5s;
}
.page--drawer-closed {
  position: absolute;
  left: 106px;
  right: 64px;
  margin-bottom: 90px;
  transition: all ease 0.5s;
}

@media print {
  .page--drawer-open,
  .page--drawer-closed {
    left: 64px;
  }
  header.mui-fixed {
    position: relative;
  }
}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}
.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 150ms linear 150ms;
}
.fade-exit {
  opacity: 1;
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

@media print {
  #printPageButton {
    display: none;
  }
  #printPageClose {
    display: none;
  }

}
